<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="day"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="day"
          :max="maxDate"
          min="2020-01-01"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
    </v-toolbar>
    <v-card>
      <v-container
        fluid
        grid-list-xs
      >
        <v-switch
          v-model="avatar"
          :label="(avatar) ? 'Show Avatar' : 'Hide Avatar'"
        />
        <v-data-iterator
          :items="items"
          item-key="dealer"
          content-tag="v-layout"
          :expand="avatar"
          hide-actions
          row
          wrap
        >
          <template #item="props">
            <v-flex
              d-flex
              xs12
              sm6
            >
              <v-card>
                <v-container grid-list-xs>
                  <v-layout
                    row
                    fill-height
                  >
                    <v-flex
                      v-if="avatar && props.item.avatar"
                      d-flex
                      xs6
                    >
                      <v-img
                        :src="props.item.avatar"
                        aspect-ratio="1"
                        contain
                      >
                        <template #placeholder>
                          <v-layout
                            fill-height
                            align-center
                            justify-center
                            ma-0
                            pa-0
                          >
                            <v-progress-circular
                              v-show="props.item.loading"
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-layout>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex
                      d-flex
                      :v-bind="avatar && props.item.avatar ? `xs6` : `xs12`"
                    >
                      <v-card
                        class="align-end"
                        flat
                      >
                        <v-card-text>
                          <div class="font-weight-bold">
                            {{ props.item.name }}
                          </div>
                          <div class="subheader">
                            {{ props.item.dealer }}
                          </div>
                        </v-card-text>
                        <v-list dense>
                          <v-list-tile>
                            <v-list-tile-content>Prepaid:</v-list-tile-content>
                            <v-list-tile-content class="align-end">
                              {{ props.item.prepaid }}
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-list-tile>
                            <v-list-tile-content>Black:</v-list-tile-content>
                            <v-list-tile-content class="align-end">
                              {{ props.item.black }}
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider />
                          <v-list-tile>
                            <v-list-tile-content>Total:</v-list-tile-content>
                            <v-list-tile-content class="align-end font-weight-bold amber--text text--darken-3">
                              {{ props.item.total }}
                            </v-list-tile-content>
                          </v-list-tile>
                        </v-list>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
          </template>
        </v-data-iterator>
      </v-container>
    </v-card>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Daily Activation Ranking'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    // WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel')
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: 'Reports', disabled: false, to: '/support/dmt_reports',
        },
        {
          text: title, disabled: true,
        },
      ],
      avatar: false,
      day: null,
      items: [],
      maxDate: '',
      menu: false,
      title,
    }
  },
  watch: {
    avatar: function (val) {
      if (!val) return
      this.items.forEach(item => {
        !item.avatar && this.getAvatar(item)
      })
    },
    day: function (val) {
      this.getDailyActivationRanking(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.day = now.toFormat('yyyy-LL-dd')
    // this.maxDate = this.day = now.plus({ days: -1 }).toFormat('yyyy-LL-dd')
  },
  methods: {
    getDailyActivationRanking: function (day) {
      const param = day ? { date: day } : {}
      this.$rest.get('getDailyActivationRanking.php', createGetParams(param))
        .then(response => {
          this.items = response.data
          if (this.avatar) {
            this.items.forEach(item => {
              !item.avatar && this.getAvatar(item)
            })
          }
        }).catch(e => {
          // ignore
        })
    },
    getAvatar: function (item) {
      item.loading = true
      this.$rest.get('getAvatar.php', createGetParams({ target: item.dealer }))
        .then(response => {
          item.avatar = response.data.avatar
          item.loading = false
        }).catch(e => {
          item.loading = false
          // ignore
        })
    },
  },
}
</script>
